import React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CssBaseline from '@mui/material/CssBaseline'

import api from '../../services/api'
import Alert from '../../components/Alert'
import Form from '../../components/Form'
import { changeForm } from '../../utils/functions'

import { useNavigate } from 'react-router-dom'

import { routes } from '../../routes/data'
import LogoV2 from '../../assets/InovaMT-PNG.png'
import LogoAzul from '../../assets/logoazul.png'
import LogoSeciteci from '../../assets/logosecitec.png'
import LogoFap from '../../assets/LOGO FAPEMAT (AZUL).png'
import LogoParc from '../../assets/LOGO PARQUE TECNOLÓGICO (PRETO).png'
import LogoRisc from '../../assets/LogoRisc.png'

import * as styles from './styles'
import { dataForm } from './data'

const Login = () => {
  const navigate = useNavigate()

  const [isSubmit, setIsSubmit] = React.useState(false)
  const [formData, setFormData] = React.useState([])

  const [typeAlert, setTypeAlert] = React.useState('')
  const [openAlert, setOpenAlert] = React.useState(false)
  const [msg, setMsg] = React.useState('')

  const handleChangeForm = (name, newValue) => {
    changeForm(formData, setFormData, name, newValue)
  }

  const handleSubmit = async () => {
    setIsSubmit(true)
    setMsg('')
    setTypeAlert('loading')

    if (
      formData.username &&
      formData.username !== '' &&
      formData.password &&
      formData.password !== ''
    ) {
      setOpenAlert(true)
      try {
        const resp = await api.post('/login', {
          username: formData.username.trim(),
          password: formData.password
        })

        if (resp.data) {
          const companySemAnswers = resp.data.user.company.map(obj => {
            const { answers, ...rest } = obj
            return rest
          })

          const tempResp = {
            ...resp.data,
            user: {
              ...resp.data.user,
              company: companySemAnswers
            }
          }

          localStorage.setItem('inovaMT@form', JSON.stringify(tempResp))
          setTypeAlert('')
          setOpenAlert(false)

          if (resp.data.user.role_uuid === 'specialist') {
            navigate(routes.avaliar)
          } else {
            navigate(routes.formulario)
          }
        }
      } catch (err) {
        console.log(err)
        setMsg('Usuário ou Senha Inválidos!')
        setTypeAlert('error')
        setOpenAlert(true)
      }
    }
  }

  return (
    <Box sx={styles.Container}>
      <CssBaseline />
      <Box src={LogoAzul} component="img" sx={styles.Logo} />
      <Box sx={styles.BoxLogoBg}>
        {/* <Box src={LogoV2} component="img" sx={styles.LogoBackground} /> */}
        {/* <Box src={LogoSeciteci} component="img" sx={styles.LogoBackground} /> */}
      </Box>
      <Box sx={styles.Box}>
        <Box sx={styles.BoxLogin}>
          <Box sx={styles.BoxLogoForm}>
            <Box src={LogoAzul} component="img" sx={styles.LogoForm} />
          </Box>
          <Typography variant="h5" sx={styles.Login}>
            Login
          </Typography>
          <Form
            data={dataForm}
            isSubmit={isSubmit}
            value={formData}
            onChange={handleChangeForm}
          />
          <Box sx={styles.LinkBox}>
            <Typography
              sx={styles.Link}
              onClick={() => navigate(routes.cadastrar)}
            >
              Cadastrar-se
            </Typography>

            <Typography
              sx={styles.Link}
              onClick={() => navigate(routes.trocar_senha)}
            >
              Esqueceu sua Senha?
            </Typography>
          </Box>
          <Box>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleSubmit}
            >
              Login
            </Button>
          </Box>
          <Box sx={styles.BoxLogoForm}>
            <Box src={LogoRisc} component="img" sx={styles.LogoRisc} />
          </Box>
        </Box>
        <Box sx={styles.BoxSuperLogo}>
          {/* <Box src={LogoSeciteci} component="img" sx={styles.SuperLogo} /> */}
          <Box src={LogoV2} component="img" sx={styles.SuperLogo} />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box 
          component='img'
          src={LogoSeciteci}
          sx={{
            width: {
              xs: '30%',
              sm: '200px',
              lg: '230px'
            },
            marginTop: 2,
          }}
        />
        <Box 
          component='img'
          src={LogoParc}
          sx={{
            width: {
              xs: '20%',
              sm: '140px',
              lg: '170px'
            },
            marginTop: 2
          }}
        />
        <Box 
          component='img'
          src={LogoFap}
          sx={{
            width: {
              xs: '30%',
              sm: '200px',
              lg: '230px'
            },
            marginTop: 2
          }}
        />
      </Box>
      <Alert
        open={openAlert}
        setOpen={setOpenAlert}
        alert={typeAlert}
        msg={msg}
        delete={() => {}}
      />
    </Box>
  )
}

export default Login
